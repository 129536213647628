<template>
  <van-popup v-model="modalOff" round position="bottom" @close="close">
    <div class="make-c">
      <div class="title">
        <span>注册</span>
      </div>
      <van-form class="make-form" @submit="onSubmit" :key='formKey'>
        <div class="make-input">
          <div class="name name-must">
            <span>联系人</span>
          </div>
          <van-field
            v-model="phoneObj.adminName"
            placeholder="请输入联系人"
            :rules="[{ required: true, message: '请输入联系人' }]"
          />
        </div>
        <div class="make-input">
          <div class="name name-must">
            <span>手机号</span>
          </div>
          <van-field
            v-model="phoneObj.adminPhone"
            placeholder="请输入手机号"
            :rules="[{ required: true, message: '请输入手机号' }]"
          />
        </div>
        <div class="make-input qr-code">
          <div class="name name-must">
            <span>手机验证码</span>
          </div>
          <van-field
            v-model="phoneObj.authCode"
            placeholder="请输入验证码"
            :rules="[{ required: true, message: '请输入验证码' }]"
          />
          <div class="showClass" v-if="phoneObj.codeNum>0">{{ phoneObj.codeNum }}s后重试</div>
          <div class="showClass" @click="getCodes" v-else>获取验证码</div>
        </div>
        <div class="make-input corp-input">
          <div class="name name-must">
            <span>企业名称</span>
          </div>
          <van-field
            v-model="phoneObj.corpSearch"
            placeholder="请输入企业名称"
            :rules="[{ required: true, message: '请输入企业名称' }]"
            right-icon="search"
            @click-right-icon="clickRight(true)"
          />
        </div>
        <div class="make-checkbox">
          <van-checkbox v-model="phoneObj.check" shape="square">我已阅读并同意<span @click.stop="showRemark">《聚优企用户协议》</span></van-checkbox>
        </div>
        <van-button class="make-btn" round block type="primary" native-type="submit">
          <span>立即注册 免费试用</span>
        </van-button>
      </van-form>
      <van-overlay :show="overlayShow" @click="overlayShow = false" :lock-scroll="false">
        <div class="wrapper" @click.stop="overlayShow = false">
          <div class="corp-c">
            <div class="li-title">
              <span>选择企业</span>
              <i class="iconfont icon-butongguo" @click.stop="overlayShow = false"></i>
            </div>
            <div class="li-box">
              <div class="li" v-for="(item, index) in agentNameList" :key="index" @click.stop="corpClick(item)">
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-overlay>
      <Verify
      v-if="verifyOff"
      ref="verify"
      :captcha-type="'blockPuzzle'"
      :img-size="{width:'260px',height:'130px'}"
      @success="loginverify"
    />
    </div>
  </van-popup>
</template>
<script>
import { Toast } from 'vant'
import cloneDeep from 'lodash/cloneDeep'
import { Base64 } from 'js-base64'
import { registerSendAuthCode, queryEnterpriseBusiness, registerTenant } from '@/util/api'
import { getFileData } from '@/util/file'
export default {
  components: {
    Verify: resolve => (require(['@/views/components/verifition/Verify.vue'], resolve))
  },
  props: {
    modalType: {
      type: [Boolean],
      default: false
    }
  },
  data () {
    return {
      overlayShow: false,
      verifyOff: false,
      istrue: false, // 人机验证回调判断
      modalOff: false,
      formKey: 0,
      codeNum: 59,
      phoneObj: {
        adminName: '', // 联系人
        adminPhone: '', // 手机号
        authCode: '', // 验证码
        corpName: '', // 公司
        corpSearch: '',
        check: false,
        timer: null,
        codeNum: false,
        codeNumr: null,
        timeShow: '获取验证码',
        captcha: '',
      },
      phoneObjOld: {
        adminName: '', // 联系人
        adminPhone: '', // 手机号
        authCode: '', // 验证码
        corpName: '', // 公司
        creditCode: '',
        corpSearch: '',
        check: false,
        timer: null,
        codeNum: false,
        codeNumr: null,
        timeShow: '获取验证码',
        captcha: '',
      },
      loading: false,
      agentNameList: []
    }
  },
  watch: {
    modalType (val) {
      this.modalOff = val
      if (!val) {
        this.formKey ++
        this.phoneObj = cloneDeep(this.phoneObjOld)
      }
    }
  },
  methods: {
    clickRight (val) {
      if (this.phoneObj.corpSearch != '') {
        queryEnterpriseBusiness({ word: this.phoneObj.corpSearch }).then(data => {
          console.log(data)
          if (data.length > 0) {
            this.agentNameList = data
            this.overlayShow = val
          } else {
            Toast.fail('未查询到企业');
          }
        })
      } else {
        Toast.fail('输入名称查询');
        this.agentNameList = []
      }
    },
    corpClick(item) {
      this.phoneObj.corpName = cloneDeep(item.name)
      this.phoneObj.corpSearch = cloneDeep(item.name)
      this.phoneObj.creditCode = cloneDeep(item.creditCode)
      this.overlayShow = false
    },
    showRemark () {
      getFileData({ _t: new Date().valueOf()}).then((fileData) => {
        console.log(fileData)
        let url = encodeURIComponent(Base64.encodeURL(fileData)) // 要预览文件的访问地址
        let name = '用户协议.docx'
        if (this.is_andriod_ios()) {
          window.open(`/preview/onlinePreview?url=${url}&fullfilename=${name}`)
        } else {
          window.location.href = `/preview/onlinePreview?url=${url}&fullfilename=${name}`
        }
      })
    },
    onSubmit() {
      let that = this
      if (!this.phoneObj.check) {
        Toast.fail('请先同意用户协议');
        // Toast.fail({
        //   duration: 100000, // 持续展示 toast
        //   forbidClick: true, // 禁用背景点击
        //   loadingType: 'spinner',
        //   message: '请先同意用户协议'
        // })
        return
      }
      if (this.phoneObj.creditCode) {
        registerTenant(this.phoneObj).then(res => {
          if (res == 0) {
            Toast.success('注册成功');
            // this.close()
            setTimeout(() => {
              that.$router.push({ name: 'guide'})
            },100)
          }
        })
      } else {
        this.clickRight(true)
      }
    },
    getCodes() {
      if (!this.phoneObj.adminPhone) {
        Toast.fail('请输入手机号');
        // Toast.loading({
        //   duration: 100000, // 持续展示 toast
        //   forbidClick: true, // 禁用背景点击
        //   loadingType: 'spinner',
        //   message: '正在加载'
        // })
        return
      }
      this.verifyShow()
      // this.phoneObj.codeNum = 60
      // this.phoneObj.codeNumr = setInterval(() => {
      //   if (this.phoneObj.codeNum <= 1) {
      //     clearInterval(this.phoneObj.codeNumr)
      //     this.phoneObj.codeNum = -1
      //     this.$forceUpdate()
      //     return
      //   }
      //   this.phoneObj.codeNum--
      //   this.$forceUpdate()
      // }, 1000)
    },
    verifyShow () {
      if (this.verifyOff) {
        this.$refs.verify.show()
      } else {
        setTimeout(() => {
          this.$refs.verify.show()
        }, 300)
      }
      this.verifyOff = true
    },
    // 人机校验回调
    loginverify (params) {
      this.phoneObj.captcha = params.captchaVerification
      this.phoneObj.time = cloneDeep(this.codeNum)
      registerSendAuthCode({ mobile: this.phoneObj.adminPhone, captcha: this.phoneObj.captcha }).then((data) => {
        this.sendV(data)
      })
    },
    sendV (data) {
      this.phoneObj.codeNum = cloneDeep(this.codeNum + 1)
      this.phoneObj.codeNumr = setInterval(() => {
        if (this.phoneObj.codeNum <= 1) {
          clearInterval(this.phoneObj.codeNumr)
          this.phoneObj.codeNum = -1
          this.$forceUpdate()
          return
        }
        this.phoneObj.codeNum--
        this.$forceUpdate()
      }, 1000)
      if (data.hadRegister) {
        // this.showTent(data)
      }
      Toast.success('获取成功');

    },
    close() {
      this.$emit('modalOff', false)
    },
  }
}
</script>
<style scoped lang="less">
.make-c {
  width: 750px;
  // height: 752px;
  background: #FFFFFF;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin: 45px 0px 50px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      font-size: 36px;
      font-weight: 500;
      color: #3F3F3F;
    }
  }
  // .make-form {
  //   max-height: 50vh;
  //   overflow-y: auto;
  // }
  .make-input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 713px;
    background: #F6F7F9;
    border-radius: 4px;
    margin-bottom: 19px;
    padding: 30px 0px 30px 39px;
    .name {
      width: 232px;
      span {
        font-size: 28px;
        font-weight: 500;
        color: rgba(63, 63, 63, 1);
      }
    }
    .name-must::before {
      content: '*';
      font-size: 28px;
      font-weight: 500;
      color: #B83A3A;
    }
    /deep/.van-cell {
      width: calc(100% - 280px);
      margin-top: 0;
      overflow: unset;
      height: auto;
      .van-field__body {
        font-size: 28px;
        font-weight: 400;
        color: #B6B6B6;
      }
    }
    /deep/.van-field__error-message{
      display: none;
    }
  }
  .corp-input {
    /deep/.van-cell {
      width: calc(100% - 240px);
      .van-icon {
        font-size: 36px;
      }
    }
  }
  .qr-code {
    /deep/.van-cell {
      width: 260px;
    }
    .showClass {
      font-size: 28px;
      font-weight: 400;
      color: #0063F9;
    }
  }
  .make-checkbox {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-weight: 400;
    color: #CDCDCD;
    margin: 60px 0px 42px 0px;
    span {
      font-size: 22px;
      font-weight: 400;
      color: rgba(0, 99, 249, 1);
    }
    /deep/.van-checkbox__label {
      font-size: 22px;
      font-weight: 400;
      color: #CDCDCD;
      line-height: 22px;
    }
  }
  .make-input:last-child {
    margin-bottom: 0px;
  }
  .make-btn {
    margin-bottom: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 713px;
    height: 88px;
    background: linear-gradient(90deg, #538AF7, #3471F7);
    border-radius: 4px;
    span {
      font-size: 36px;
      font-weight: 500;
      color: #F6F9FF;
    }
  }
}
.wrapper {
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .corp-c {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 0px 20px 20px 20px;
    .li-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0px;
      border-bottom: 1px solid #eeeeee;
      span {
        font-size: 36px;
        font-weight: 400;
        color: #3F3F3F;
      }
      i {
        font-size: 24px;
      }
    }
    .li-box {
      display: flex;
      flex-direction: column;
      max-height: 600px;
      min-height: 200px;
      // overflow: hidden;
      overflow-y: auto;
      touch-action: pan-y;
      .li {
        flex-shrink: 0;
        width: 540px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        padding: 20px 10px;
        span {
          font-size: 28px;
          font-weight: 400;
          color: #3F3F3F;
        }
      }
    }
    .li:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
